#hamburgerMenu {
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  top: 0;
  z-index: 100;
}

#hamburgerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-variant);
  height: 100%;
  border-radius: 0 50px 50px 0;
}

#hamburgerIconSpan {
  margin: 0 auto;
  width: 100%;
  cursor: pointer;
}

#pannel {
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: var(--primary);
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}


.pannelShow p {
  font-size: 0.5em;
}


#pannelBody {
  overflow-y: scroll;
  padding: 10px;
}

.aboutIcon {
  height: 32px;
  width: 32px;
  background-color: #171516;
  border-radius: 50px;
  text-align: center;
  vertical-align: middle;
}

.aboutIcon i {
  margin: auto;
  color: var(--primary);
}
