:root {
  /*Variables*/
  --primary: #26C6DA;
  --primary-variant: #00BCD4;
}

.App {
  background-color: #d4d4d4;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #414755;
}

body, .App {
  overflow: hidden;
}

a:visited, a {
  color: #414755;
}

#GraphCanvas, #cyroot {
  height: 100%;
  width: 100%;
}

#GraphCanvas #btnlist{
  z-index: 3;
  list-style: none;
  position: absolute;
  display: flex;
  gap: 10px;
  bottom: 0;
  right: 10px;
}
